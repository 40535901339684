import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import EmailIllustrationSrc from "images/email-illustration.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
/* const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`; */
const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-4`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);


const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left
mt-[0px] mr-[25px]`;

const Subheading = tw(SubheadingBase)`text-center md:text-left text-primary-1000`;
//const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight `;
const Heading = tw(SectionHeading)`
  mt-4 font-black text-left text-lg sm:text-xl lg:text-2xl text-center md:text-left leading-tight
`;
//const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`
const CenterHeading = tw.p`text-center font-black  sm:text-3xl lg:text-4xl`
const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer { 
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none whitespace-nowrap`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose `}
    text-overflow: ellipsis;
  text-align: justify;
  white-space: normal;
  word-break: break-word;
  overflow-wrap: break-word; 
  }
`;
const TourHeading = tw.p`text-center font-black  sm:text-3xl lg:text-4xl`;
const TwoColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;

const Description = styled.p`
  ${tw`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`}
  text-align: justify;
  word-spacing: 5px; /* Adjust the spacing between words */
`;

export default ({
  cards = null,
  subheading = "Training Partner",
  heading = <>List of features provided by <span style={{
    background: '-webkit-linear-gradient(left,#02c197, #006a9a)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: "25px",
  }}>TPO.AI <> <span style={{ background: "rgb(36 62 99 / var(--tw-text-opacity))", WebkitBackgroundClip: 'text', }}>to</span></>  training partners</span><wbr /></>,
  description = " ",
  submitButtonText = "Contact Me",

  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on the left or right side of the image.
  const defaultCards = [
    {
      imageSrc: "gif_files/Tp_Gifs/profile Builder.gif",
      title: "Profile Builder",
      description: "User-friendly interface for training partners to create comprehensive profiles.Add accreditation, expertise, and showcase specialties.",
      color: 'red'
    },
    {
      imageSrc: "https://cdn.pixabay.com/photo/2016/12/06/01/26/colour-1885352_640.jpg",
      title: "Program Management",
      description: "Easily list, describe, and manage various courses, programs, and training offerings. Upload course materials, syllabi, and relevant resources.",
      color: 'blue'

    },
    {
      imageSrc: "gif_files/Tp_Gifs/learning Paths.gif",
      title: "Learning Paths",
      description: "Design personalized training paths to cater to the diverse needs of individual students.Offer flexibility in creating certification programs, workshops, and specialized courses.",
      color: 'yellow'

    },
    {
      imageSrc: "gif_files/Tp_Gifs/Library_Management.gif",
      title: "Library Management",
      description: "Centralized platform for managing and updating course materials, quizzes, assignments, and multimedia content.",
      color: 'pink'

    },
    {
      imageSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRU54eyJ_fzl9Gy4ZKzZ9fnObJjMsp6yCfgSA&usqp=CAU",
      title: "LMS Integration",
      description: "Integrate with popular Learning Management Systems for a smooth transfer of course content onto the TPO.AI platform.",
      color: 'brown'

    },
    {
      imageSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREtLoJmv6c_zyeH25zH2i1EuBugrCZE62dMQ&usqp=CAU",
      title: "Calendar Tools:",
      description: "Set availability for classes, workshops, and one-on-one sessions through an intuitive scheduling system.Sync with calendar tools for efficient time management.",
      color: 'black'

    },
    {
      imageSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRU54eyJ_fzl9Gy4ZKzZ9fnObJjMsp6yCfgSA&usqp=CAU",
      title: "Communication Hub",
      description: "Robust communication features, including messaging, discussion forums, and announcement boards.Foster interaction between training partners and students.",
      color: 'brown'

    },
    {
      imageSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREtLoJmv6c_zyeH25zH2i1EuBugrCZE62dMQ&usqp=CAU",
      title: "Analytics and Reporting",
      description: "Access analytics tools to monitor student engagement, progress, and performance.Generate detailed reports on course completion rates, assessment scores, and other relevant metrics.",
      color: 'black'

    },
    {
      imageSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRU54eyJ_fzl9Gy4ZKzZ9fnObJjMsp6yCfgSA&usqp=CAU",
      title: "Payment Gateway",
      description: "Integrated payment gateways for secure financial transactions related to course enrollments.Revenue management tools to track earnings and payouts.",
      color: 'brown'

    },
    {
      imageSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREtLoJmv6c_zyeH25zH2i1EuBugrCZE62dMQ&usqp=CAU",
      title: "AI-Driven",
      description: "Leverage AI algorithms to match students with the most suitable training partners based on learning preferences, goals, and skill levels.",
      color: 'black'

    },
    {
      imageSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRU54eyJ_fzl9Gy4ZKzZ9fnObJjMsp6yCfgSA&usqp=CAU",
      title: "Marketing & Promotion ",
      description: "Tools to promote courses within the TPO.AI ecosystem and attract a larger audience.Marketing resources and strategies to enhance course visibility.",
      color: 'brown'

    },
    {
      imageSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREtLoJmv6c_zyeH25zH2i1EuBugrCZE62dMQ&usqp=CAU",
      title: "Support & Training",
      description: "Onboarding support and training resources for a smooth integration into the TPO.AI platform.Ongoing assistance through a dedicated support system.",
      color: 'black'

    }

  ];


  const Clients = [
    {
      img: 'clients/raceel logo.png'
    },
    {
      img: 'clients/fullstack.png'
    }
  ]

  if (!cards) cards = defaultCards;

  return (
    <Container id="trainingpartner">
      <TourHeading>Product Tour</TourHeading>
      <TwoColumn>
        {/*  <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
 */}
        <iframe width="660" height="315" style={{ marginLeft: '10px' }} src="https://www.youtube.com/embed/erpXVMlngAA?si=7s9d9YEHL0SrXdo1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        <TextColumn >
          <TextContent>
            {subheading && <Subheading style={{
              marginTop: "-60px",
              background: '-webkit-linear-gradient(left,#02c197, #006a9a)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              fontSize: "45px"
            }}>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {/* <Description>{description}</Description> */}
          </TextContent>

          <marquee>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {Clients.map((client, index) => (
                <img
                  key={index}
                  src={client.img}
                  style={{ marginRight: "10px", marginBottom: "10px", width: '220px' }}
                />
              ))}
            </div>
          </marquee>
 


        </TextColumn>
      </TwoColumn>
      <CenterHeading>Features</CenterHeading>
      <TwoColumnContainer>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc} alt="" width="350px" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description}

                </p>
              </span>
            </Card>
          </Column>
        ))}
      </TwoColumnContainer>
    </Container>
  );
};
